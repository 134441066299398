<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model :colon="false" layout="inline">
        <a-form-model-item>
          <a-input v-model="name" placeholder="项目名称" style="width: 150px"></a-input>
        </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table bordered :data-source="filteredList" :loading="loading" rowKey="id">
        <a-table-column title="项目名称" data-index="name" />
        <a-table-column title="生产部门" data-index="productionUnitName" align="center" />

        <a-table-column title="检查次数" align="center">
          <template slot-scope="text">
            <span>{{ text.count ? text.count : '--' }}</span>
          </template>
        </a-table-column>

        <a-table-column title="开工状态" align="center">
          <template slot-scope="text">
            <div>
              <a-badge v-if="text.kickOffStatus === true" status="success" text="已开工" />
              <a-badge v-else-if="text.kickOffStatus === false" status="error" text="未开工" />
              <span v-else>--</span>
            </div>
          </template>
        </a-table-column>
        <a-table-column title="竣工状态" align="center">
          <template slot-scope="text">
            <a-badge v-if="text.completeStatus === true" status="success" text="已竣工" />
            <a-badge v-else-if="text.completeStatus === false" status="error" text="未竣工" />
            <span v-else>--</span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="openDetail(text)">查看</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";

function fetchList() {
  return request({
    url: "/office-service/quality/inspect/project/queryProjectList"
  });
}

export default {
  mixins: [watermark],

  data() {
    return {
      name: "",
      loading: false,
      list: []
    };
  },

  computed: {
    filteredList() {
      if (this.name) {
        return this.list.filter(
          item => item.name && item.name.indexOf(this.name) > -1
        );
      } else {
        return this.list;
      }
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList()
        .then(res => {
          if (Array.isArray(res)) {
            this.list = Object.freeze(res);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    openDetail(text) {
      this.$router.push(
        "/technical-quality/security-check-management/security-check?projectName=" +
          text.name
      );
    }
  }
};
</script>
